body {
  max-height: 700px;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0px;
  padding: 0px;
  font-size: 14px;

  font-family: 'Roc Grotesk';
  font-weight: 400;
  overflow-y: scroll;
}

@font-face {
  font-family: 'Roc Grotesk';
  src: url('@/assets/fonts/Roc Grotesk Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roc Grotesk';
  src: url('@/assets/fonts/Roc Grotesk Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roc Grotesk';
  src: url('@/assets/fonts/Roc Grotesk Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
