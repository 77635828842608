.lds-roller div {
  width: 21.1px;
  height: 21.1px;
  border-radius: 50%;
  background: #fff;
  color: #fff;
  clip-path: inset(-45.8px);
  animation: lds-roller 2.4s infinite linear;
}

@keyframes lds-roller {
  0% {
    box-shadow: 0 0 0 0, 70.4px 0, -70.4px 0, 0 70.4px, 0 -70.4px;
  }
  10% {
    box-shadow: 0 0 0 0, 21.1px 0, -70.4px 0, 0 70.4px, 0 -70.4px;
  }
  20% {
    box-shadow: 0 0 0 7px, 0 0, -70.4px 0, 0 70.4px, 0 -70.4px;
  }
  30% {
    box-shadow: 0 0 0 7px, 0 0, -21.1px 0, 0 70.4px, 0 -70.4px;
  }
  40% {
    box-shadow: 0 0 0 14.1px, 0 0, 0 0, 0 70.4px, 0 -70.4px;
  }
  50% {
    box-shadow: 0 0 0 14.1px, 0 0, 0 0, 0 21.1px, 0 -70.4px;
  }
  60% {
    box-shadow: 0 0 0 21.1px, 0 0, 0 0, 0 0, 0 -70.4px;
  }
  70% {
    box-shadow: 0 0 0 21.1px, 0 0, 0 0, 0 0, 0 -21.1px;
  }
  80% {
    box-shadow: 0 0 0 28.2px, 0 0, 0 0, 0 0, 0 0;
  }
  90%,
  100% {
    box-shadow: 0 0 0 0, 70.4px 0, -70.4px 0, 0 70.4px, 0 -70.4px;
  }
}
